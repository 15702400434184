/* eslint-disable wc/guard-super-call */
import React, { createElement } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import CodeMirror from '@uiw/react-codemirror';
import { githubLight } from '@uiw/codemirror-theme-github';
import type { Extension } from '@codemirror/state';
import { BaseElementWithGlobalStyles, customElement } from '../../base-element';

import styles from './code-editor.scss?inline';

@customElement('ps-code-editor')
export class CodeEditorWC extends BaseElementWithGlobalStyles {
  static styles = unsafeCSS(styles);

  root: Root;

  @property() height?: string;

  @property({ type: Array }) extensions?: Extension[];

  @property() value?: string;

  connectedCallback() {
    super.connectedCallback();
    if (!this.root) {
      this.root = createRoot(this.renderRoot);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    // this.root.unmount();
  }

  render() {
    this.root.render(
      createElement(CodeMirror, {
        height: this.height,
        extensions: this.extensions,
        value: this.value,
        theme: githubLight,
      })
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-code-editor': CodeEditorWC;
  }
  enum PSElementTagNameMap {
    'ps-code-editor' = 'ps-code-editor',
  }
}
