import { html, css, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property, query } from 'lit/decorators.js';
import { BaseElement, customElement, watch } from '../base-element';

import styles from './tab.scss?inline';
import { Icons } from '../icon/icon.types';

export const tagName = 'ps-tab';

export interface Tabs {
  text: string;
  icon: keyof typeof Icons;
}

@customElement('ps-tab')
export class TabWC extends BaseElement {
  static styles = css`
    ${unsafeCSS(styles)}
  `;

  @query('.tab') tab: HTMLElement;

  /** The name of the tab panel this tab is associated with. The panel must be located in the same tab group. */
  @property({ reflect: true }) panel = '';

  /** Items within each tab (Array of strings currently not sure what implementation will look like) */

  /** Draws the tab in an active state. */
  @property({ type: Boolean, reflect: true }) active = false;

  /** Disables the tab and prevents selection. */
  @property({ type: Boolean, reflect: true }) disabled = false;

  connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();
    this.setAttribute('role', 'tab');
  }

  @watch('active')
  handleActiveChange() {
    this.setAttribute('aria-selected', this.active ? 'true' : 'false');
  }

  @watch('disabled')
  handleDisabledChange() {
    this.setAttribute('aria-disabled', this.disabled ? 'true' : 'false');
  }

  /** Sets focus to the tab. */
  focus(options?: FocusOptions) {
    this.tab.focus(options);
  }

  /** Removes focus from the tab. */
  blur() {
    this.tab.blur();
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.code === 'Space') {
      this.active = true;
      const tabGroup = this.parentNode as HTMLElement;
      const tabs = tabGroup.querySelectorAll(tagName);
      for (const tab of tabs) {
        if (tab !== this) {
          tab.active = false;
        }
      }
    }
  }

  render() {
    return html`
      <button
        class=${classMap({
          'c-tab': true,
          'c-tab--active': this.active,
          'c-tab--disabled': this.disabled,
        })}
        tabindex=${this.disabled ? '-1' : '0'}
        @keydown=${this.handleKeyDown}
      >
        <slot></slot>
      </button>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-tab': TabWC;
  }
  enum PSElementTagNameMap {
    'ps-tab' = 'ps-tab',
  }
}
