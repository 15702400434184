/* eslint-disable no-console */
import { css, html } from 'lit';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit-html/directives/if-defined.js';

import {
  BaseElement,
  customElement,
  watch,
} from '../../components/base-element';
import { smartService } from '../xstate/smart.xstate';
import { SmartContext } from '../xstate/smart.xstate-utils';

import '../../components/nav/nav.wc';
import '../../components/nav/nav-logo.wc';
import '../../components/nav/nav-links.wc';
import '../../components/nav/nav-link.wc';
import '../../components/buttons/icon-button/icon-button.wc';
import '../../components/menu/src/menu-item.wc';
import '../universal-user-dropdown/universal-user-dropdown-trigger.wc';
import '../universal-user-dropdown/universal-user-dropdown.wc';
import '../mega-menu/mega-menu.wc';

/**
 * ### Component Description ###
 * `ps-universal-nav` is a component that provides a navigation bar with support for:
 *  - *logo*
 *  - *navigation links*
 *  - *additional actions*
 *  - *mega-menu*
 *  - *profile dropdown*
 *
 *
 * ### Features ###
 * The component fetches necessary data (user information, projects, products and their roles) to display information in ***Mega Menu***
 *
 * The component fetches user information and displays the *user's name*, *email*, and *profile photo* in the ***Profile Dropdown***
 */
@customElement('ps-universal-nav')
export class UniversalNavWC extends BaseElement {
  static styles = css`
    :host {
      display: contents;
    }
  `;

  context: SmartContext;

  /**
   * current ***Organization id***
   */
  @property({ reflect: false }) org?: string;

  @watch('org')
  orgChanged() {
    this.init();
  }

  /**
   * current ***Project id***
   */
  @property({ reflect: false }) project?: string;

  /**
   * optional setting to hide the "go back home" link that normally shows up
   */
  @property({ reflect: false, type: Boolean }) hideGoHome?: boolean = false;

  private onProjectSelect(selection: string | undefined) {
    this.emit('project-select', { detail: { selection } });
  }

  constructor() {
    super();
    this.init = this.init.bind(this);
  }

  async connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();

    this.init();

    smartService.subscribe((smartState) => {
      this.context = smartState.context;
    });
  }

  init() {
    if (!this.org || this.context.accountId === this.org) {
      return;
    }

    smartService.send({
      type: 'changeOrg',
      org: this.org,
    });
  }

  render() {
    return html`
      <ps-nav>
        <slot name="logo"></slot>
        <slot name="navigation"></slot>

        <ps-nav-actions>
          <slot name="actions"></slot>
          <ps-mega-menu
            project=${ifDefined(this.project)}
            ?hidegohome=${this.hideGoHome}
            .onProjectSelect=${this.onProjectSelect}
          ></ps-mega-menu>
        </ps-nav-actions>

        <slot></slot>
      </ps-nav>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-universal-nav': UniversalNavWC;
  }
  enum PSElementTagNameMap {
    'ps-universal-nav' = 'ps-universal-nav',
  }
}
