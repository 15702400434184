import React from "react";
import __pages_import_0__ from "/src/routes/index.tsx";
const __pages_import_1__ = React.lazy(() => import("/src/routes/organization.$org_id.projects.$project_id.release-channels.$release_channel_id.applets.$applet_id.tsx"));
const __pages_import_2__ = React.lazy(() => import("/src/routes/organization.$org_id.projects.$project_id.release-channels.tsx"));
const __pages_import_3__ = React.lazy(() => import("/src/routes/organization.$org_id.projects.$project_id.tsx"));
const __pages_import_4__ = React.lazy(() => import("/src/routes/organization.$org_id.projects.tsx"));
const __pages_import_5__ = React.lazy(() => import("/src/routes/organization.$org_id.release-channels.$release_channel_id.tsx"));
const __pages_import_6__ = React.lazy(() => import("/src/routes/organization.$org_id.super-admin.tsx"));
const __pages_import_7__ = React.lazy(() => import("/src/routes/organization.$org_id.teams.$team_id.tsx"));
const __pages_import_8__ = React.lazy(() => import("/src/routes/organization.$org_id.teams.tsx"));
const __pages_import_9__ = React.lazy(() => import("/src/routes/organization.$org_id.tsx"));
const __pages_import_10__ = React.lazy(() => import("/src/routes/organization.$org_id.user-preferences.tsx"));
const __pages_import_11__ = React.lazy(() => import("/src/routes/organization.$org_id.users.$user_id.tsx"));
const __pages_import_12__ = React.lazy(() => import("/src/routes/organization.$org_id.users.tsx"));

const routes = [{"caseSensitive":false,"path":"/","element":React.createElement(__pages_import_0__)},{"caseSensitive":false,"path":"organization/:org_id/projects/:project_id/release-channels/:release_channel_id/applets/:applet_id","element":React.createElement(__pages_import_1__)},{"caseSensitive":false,"path":"organization/:org_id/projects/:project_id/release-channels","element":React.createElement(__pages_import_2__)},{"caseSensitive":false,"path":"organization/:org_id/projects/:project_id","element":React.createElement(__pages_import_3__)},{"caseSensitive":false,"path":"organization/:org_id/projects","element":React.createElement(__pages_import_4__)},{"caseSensitive":false,"path":"organization/:org_id/release-channels/:release_channel_id","element":React.createElement(__pages_import_5__)},{"caseSensitive":false,"path":"organization/:org_id/super-admin","element":React.createElement(__pages_import_6__)},{"caseSensitive":false,"path":"organization/:org_id/teams/:team_id","element":React.createElement(__pages_import_7__)},{"caseSensitive":false,"path":"organization/:org_id/teams","element":React.createElement(__pages_import_8__)},{"caseSensitive":false,"path":"organization/:org_id","element":React.createElement(__pages_import_9__)},{"caseSensitive":false,"path":"organization/:org_id/user-preferences","element":React.createElement(__pages_import_10__)},{"caseSensitive":false,"path":"organization/:org_id/users/:user_id","element":React.createElement(__pages_import_11__)},{"caseSensitive":false,"path":"organization/:org_id/users","element":React.createElement(__pages_import_12__)}];

export default routes;