import { AppletInstance, DomainTypeEnum } from '@pypestream/api-services';
import {
  BlockList,
  Button,
  Divider,
  Icon,
  PageSection,
  Spacer,
  Stack,
  TextOverline,
  TextSubtitle,
  TextTitle,
} from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';
import { FC } from 'react';
import { useFieldArray } from 'react-hook-form';

import { ProjectReleaseChannelField } from '.';
import {
  AppletGQLPlaygroundButton,
  ReturnFormType,
} from '../../../../components';
import { ReleaseChannelApplets } from './release-channel-applets';

interface ProjectSettingsReleaseChannelsTabProps {
  form: ReturnFormType;
  accountId?: string;
  projectId?: string;
}

const defaultDomain = { type: DomainTypeEnum.Domain, url: '' };

export const ProjectSettingsReleaseChannelsTab: FC<
  ProjectSettingsReleaseChannelsTabProps
> = ({ form, accountId, projectId }) => {
  const { control, getFieldState, getValues } = form;

  const productionField = useFieldArray({
    control,
    name: 'projectReleaseChannelConfigs.0.domains',
    rules: {
      minLength: 1,
    },
  });

  const stageField = useFieldArray({
    control,
    name: 'projectReleaseChannelConfigs.1.domains',
  });

  const developmentField = useFieldArray({
    control,
    name: 'projectReleaseChannelConfigs.2.domains',
  });
  const [prodReleaseChannelId, stageReleaseChannelId, devReleaseChannelId] =
    getValues([
      'projectReleaseChannelConfigs.0.id',
      'projectReleaseChannelConfigs.1.id',
      'projectReleaseChannelConfigs.2.id',
    ]);

  const [applets] = getValues(['applets']);

  return (
    <>
      <TextTitle size="small" i18nKey="manager/projects:releaseChannels.title">
        Deployment Overview
      </TextTitle>
      <Spacer size="2xlarge" />
      <PageSection>
        <TextTitle
          size="xsmall"
          i18nKey="manager/projects:releaseChannels.caption"
        >
          Release Channels
        </TextTitle>
        <TextSubtitle
          size="small"
          variant="tertiary"
          i18nKey="manager/projects:releaseChannels.description"
        >
          Projects organize work around an online property like a website.
          Everything in the project must be tied to a domain and, optionally,
          its subdomains.
        </TextSubtitle>
        <Spacer size="large" />

        <PageSection>
          <Stack
            gutter="2xsmall"
            alignItems="center"
            justifyContent="space-between"
            nowrap
          >
            <TextOverline>
              <TranslationComponent i18nKey="manager/projects:releaseChannels.production.caption">
                PRODUCTION
              </TranslationComponent>
            </TextOverline>
            <AppletGQLPlaygroundButton
              accountId={accountId}
              channelId={prodReleaseChannelId}
              i18nKey="manager/projects:releaseChannels.production.gqlPlaygroundsButton"
            />
          </Stack>
          <Spacer size="small" />
          <BlockList gutter="small">
            {productionField.fields.map(({ id }, index) => (
              <Stack
                key={id}
                gutter="2xsmall"
                alignItems="center"
                justifyContent="space-between"
                nowrap
              >
                <ProjectReleaseChannelField
                  name={`projectReleaseChannelConfigs.0.domains`}
                  index={index}
                  remove={productionField.remove}
                  isForbiddenForRemove={!index}
                  form={form}
                  validate={
                    getFieldState(
                      `projectReleaseChannelConfigs.0.domains.${index}.url`
                    ).isDirty
                  }
                  placeholder={
                    index === 0 ? 'https://www.pypestream.com/product/' : ''
                  }
                />
              </Stack>
            ))}
          </BlockList>

          <Spacer size="small" />
          <Button
            variant="ghost"
            size="large"
            onClick={() => productionField.append(defaultDomain)}
          >
            <TranslationComponent i18nKey="manager/projects:releaseChannels.production.addProductionUrl">
              Add Production URL
            </TranslationComponent>
            <Icon slot="prefix" name="add" />
          </Button>
          <Spacer size="small" />
          <Divider></Divider>
          <ReleaseChannelApplets
            applets={applets.filter(
              (applet: AppletInstance) =>
                applet.projectReleaseChannelConfigId === prodReleaseChannelId
            )}
            accountId={accountId}
            projectId={projectId}
            releaseChannelId={prodReleaseChannelId}
          />
        </PageSection>

        <Spacer size="small" />

        <PageSection>
          <Stack
            gutter="2xsmall"
            alignItems="center"
            justifyContent="space-between"
            nowrap
          >
            <TextOverline>
              <TranslationComponent i18nKey="manager/projects:releaseChannels.testing.caption">
                Testing
              </TranslationComponent>
            </TextOverline>
            <AppletGQLPlaygroundButton
              accountId={accountId}
              channelId={stageReleaseChannelId}
              i18nKey="manager/projects:releaseChannels.testing.gqlPlaygroundsButton"
            />
          </Stack>
          <Spacer size="small" />
          <BlockList gutter="small">
            {stageField.fields.map(({ id }, index) => (
              <Stack
                key={id}
                gutter="2xsmall"
                alignItems="center"
                justifyContent="space-between"
                nowrap
              >
                <ProjectReleaseChannelField
                  name={`projectReleaseChannelConfigs.1.domains`}
                  index={index}
                  remove={stageField.remove}
                  form={form}
                  validate={
                    getFieldState(
                      `projectReleaseChannelConfigs.1.domains.${index}.url`
                    ).isDirty
                  }
                  placeholder={
                    index === 0 ? 'https://www.pypestream.com/product/' : ''
                  }
                />
              </Stack>
            ))}
          </BlockList>
          <Spacer size="small" />
          <Button
            variant="ghost"
            size="large"
            onClick={() => stageField.append(defaultDomain)}
          >
            <TranslationComponent i18nKey="manager/projects:releaseChannels.testing.addStageUrl">
              Add Stage URL
            </TranslationComponent>
            <Icon slot="prefix" name="add" />
          </Button>
          <Spacer size="small" />
          <Divider></Divider>
          <ReleaseChannelApplets
            applets={applets.filter(
              (applet: AppletInstance) =>
                applet.projectReleaseChannelConfigId === stageReleaseChannelId
            )}
            accountId={accountId}
            projectId={projectId}
            releaseChannelId={stageReleaseChannelId}
          />
        </PageSection>

        <Spacer size="small" />

        <PageSection>
          <Stack
            gutter="2xsmall"
            alignItems="center"
            justifyContent="space-between"
            nowrap
          >
            <TextOverline>
              <TranslationComponent i18nKey="manager/projects:releaseChannels.development.caption">
                DEVELOPMENT
              </TranslationComponent>
            </TextOverline>
            <AppletGQLPlaygroundButton
              accountId={accountId}
              channelId={devReleaseChannelId}
              i18nKey="manager/projects:releaseChannels.development.gqlPlaygroundsButton"
            />
          </Stack>
          <Spacer size="small" />

          <BlockList gutter="small">
            {developmentField.fields.map(({ id }, index) => (
              <Stack
                key={id}
                gutter="2xsmall"
                alignItems="center"
                justifyContent="space-between"
                nowrap
              >
                <ProjectReleaseChannelField
                  name={`projectReleaseChannelConfigs.2.domains`}
                  index={index}
                  remove={developmentField.remove}
                  form={form}
                  validate={
                    getFieldState(
                      `projectReleaseChannelConfigs.2.domains.${index}.url`
                    ).isDirty
                  }
                  placeholder={
                    index === 0 ? 'https://www.pypestream.com/product/' : ''
                  }
                />
              </Stack>
            ))}
          </BlockList>
          <Button
            variant="ghost"
            size="large"
            onClick={() => developmentField.append(defaultDomain)}
          >
            <TranslationComponent i18nKey="manager/projects:releaseChannels.development.addDevelopmentUrl">
              Add Development URL
            </TranslationComponent>
            <Icon slot="prefix" name="add" />
          </Button>
          <Spacer size="small" />
          <Divider></Divider>
          <ReleaseChannelApplets
            releaseChannelId={devReleaseChannelId}
            accountId={accountId}
            projectId={projectId}
            applets={applets.filter(
              (applet: AppletInstance) =>
                applet.projectReleaseChannelConfigId === devReleaseChannelId
            )}
          />
        </PageSection>
      </PageSection>
    </>
  );
};
